<template>
  <div>
    <el-row>
      <el-col class="floot-content" :span="24">
        <div class="container">
          <el-row style="padding-top: 60px;">
            <el-col class="dis-bet-cen" :span="24">
              <el-image fit="contain" :src="logo"></el-image>
              <div class="floot-nav-content">
                <span @click="handleToLink(item)" v-for="(item, index) of listNav" :key="index" class="floot-nav">{{item.name}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row style="padding-top: 60px;">
            <el-col class="dis-sta-sta" :span="12">
              <div style="text-align: left;width: 200px;">
                <div class="floot-file-title">关于我们</div>
                <ul class="floot-ul">
                  <li class="li-hover" v-for="(item, index) of fileNav" @click="handleToAgreement(item)" :key="index">{{item.name}}</li>
                </ul>
              </div>
              <div>
                <div class="floot-file-title">联系我们</div>
                <ul class="floot-ul">
                  <li v-for="(item, index) of introduceNav" :key="index" style="cursor:default;">{{item.name}}</li>
                </ul>
              </div>
            </el-col>
            <el-col class="dis-end-sta" :span="12">
              <div style="width: 200px;text-align: left;">
                <div class="floot-file-title">关注我们</div>
                 <el-image fit="contain" :src="imgList.account"></el-image>
              </div>
              <div>
                <div class="floot-file-title">E查工程小程序</div>
                <el-image fit="contain" :src="imgList.engineering"></el-image>
              </div>
            </el-col>
          </el-row>
          <el-row style="padding-top: 10px;">
            <el-col class="dis-cen-cen" style="font-size: 14px;color: #666;"  :span="24">@2021 易信（厦门）信用服务技术有限公司 <span class="link-class" @click="handleToLinkBa()"> 闽ICP备20014246号-1</span></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'flootNav',
  data () {
    return {
      logo: require('../../assets/index/logo-d.png'),
      listNav: [
        {
          name: '首页',
          url: 'index'
        },
        {
          name: '企业报告',
          url: 'report'
        },
        {
          name: 'VIP会员',
          url: 'vip-index'
        },
        {
          name: '数据维护',
          url: 'data-correction'
        },
        {
          name: '关于我们',
          url: 'about'
        }
      ],
      fileNav: [
        // {
        //   name: '关于我们',
        //   url: 'aboutMe'
        // },
        {
          name: '会员服务协议',
          url: 'vipServiceAgreement'
        },
        {
          name: '免责声明',
          url: 'disclaimer'
        },
        {
          name: '隐私政策',
          url: 'privacyPolicy'
        },
        {
          name: '企业征信授权书',
          url: 'creditAuthorization'
        }
      ],
      introduceNav: [
        {
          name: ''
        },
        {
          name: 'E-mail：service@e2winner.com'
        },
        {
          name: '地址：厦门市集美区诚毅北大街53号软件园三期B12栋'
        },
        {
          name: '国家信用大数据创新中心3楼'
        }
      ],
      imgList: {
        engineering: require('../../assets/index/e.png'),
        account: require('../../assets/index/g.png')
      },
      myCustomerServiceTel: ''
    }
  },
  computed: {
    ...mapGetters([
      'customerServiceTel'
    ]),
    customerServiceTelChange: function () {
      const vm = this
      // console.log('计算属性: ' + JSON.parse(vm.vipData).vipLevel)
      if (vm.customerServiceTel) {
        return vm.customerServiceTel
      } else {
        return null
      }
    }
  },
  watch: {
    customerServiceTelChange: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.myCustomerServiceTel = newValue
        if (this.myCustomerServiceTel) {
          this.introduceNav[0].name = '联系电话：' + this.myCustomerServiceTel
        }
      }
    }
  },
  methods: {
    handleToAgreement (e) {
      const vm = this
      sessionStorage.removeItem('about-item')
      sessionStorage.setItem('about-item', e.url)
      const routeData = vm.$router.resolve({
        name: 'about'
      })
      window.open(routeData.href, '_blank')
    },
    handleToLink (e) {
      this.$router.push({
        name: e.url
      })
    },
    handleToLinkBa () {
      const url = 'https://beian.miit.gov.cn/#/Integrated/index'
      window.open(url, '_black')
    }
  }
}
</script>

<style lang="less" scoped="scoped">
.floot-content {
  background-color: #0F1314;
  height: 410px;
  .floot-nav-content {
    .floot-nav {
      color: #999999;
      font-size: 16px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 30px;
      cursor: pointer;
    }
    .floot-nav:hover {
      color: #e5e5e5;
    }
  }
  .floot-file-title{
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
  }
  .floot-ul li{
    list-style: none;
    margin-top: 10px;
    font-size: 14px;
    color: #666666;
    text-align: left;
    cursor: pointer;
  }
  .li-hover:hover{
    color: #fff;
  }
}
.link-class {
  font-size: 14px;
  color: #666;
  cursor: pointer;
}
.link-class:hover{
  color: #E9E9E9;
}
</style>
